@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
/******** Default CSS *********/

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Lora", serif;
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  line-height: 140%;
  background: #000;
}
a,
a:hover {
  text-decoration: none;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}
p {
  font-family: "Lora", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  line-height: 150%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #040404;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #040404;
}

::-webkit-scrollbar-thumb {
  background-color: #cdab62;
}
.btn {
  background: #cdab62;
  font-size: 18px;
  line-height: 27px;
  padding: 12px 32px;
  color: #000;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s all ease-in-out;
  border: 2px solid #cdab62;
  cursor: pointer;
}
.btn:hover {
  background: transparent;
  color: #cdab62;
}
.btn-outline {
  background: #000;
  border: 1px solid #cdab62;
  color: #cdab62;
}
.btn-outline:hover {
  background: #cdab62;
  color: #000;
}
.btn-large {
  padding: 17px 96px;
}
.btn-medium {
  padding: 12px 35px;
}
.btn-small {
  padding: 5px 15px;
  line-height: 20px;
}
.btn-wrapper .btn {
  display: inline-block;
}
.btn-wrapper {
  margin-top: 40px;
}

@media (max-width: 599px) {
  .btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .btn-wrapper .btn {
    display: block;
    text-align: center;
    width: 90%;
  }
}



